class Constants {
    static BASE_URL = "http://192.168.0.127:3001";//"https://api.kushdrop.app";
    static GET_PRODUCTS_URL = this.BASE_URL + "/api/admin/products";
    static GET_ADD_PRODUCTS_DATA_URL = this.BASE_URL + "/api/admin/get-add-product-data"
    static CREATE_PRODUCT = this.BASE_URL +"/api/admin/create-product"
    static GET_CATEGORIES_URL = this.BASE_URL + "/api/admin/categories";
    static CREATE_CATEGORIES_URL = this.BASE_URL + "/api/admin/create-category";
    static DELETE_SERVICE_URL = this.BASE_URL + "/api/admin/delete";

    static GET_SIZES_URL = this.BASE_URL + "/api/admin/sizes";
    static CREATE_SIZES_URL = this.BASE_URL + "/api/admin//create-size";
}

export default Constants;