import React from 'react';

const Orders = () => {
    return (
        <div className="orders">
            <h2>Orders</h2>
            <p>Manage and track customer orders here.</p>
        </div>
    );
};

export default Orders;
