import Constants from "../utils/Constants";


// Fetch all categories
export const getCategories = async () => {
    const response = await fetch(Constants.GET_CATEGORIES_URL);
    const data = await response.json();
    return data;
};

// Add a new category
export const addCategory = async (category) => {
    const response = await fetch(Constants.CREATE_CATEGORIES_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(category)
    });
    return response.json();
};
