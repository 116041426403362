import React, { useState, useEffect } from 'react';
import { getCategories, addCategory } from '../../services/categoryService';
import { storage } from '../../configs/firebaseConfig'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import AWS from 'aws-sdk';


const s3 = new AWS.S3({
    accessKeyId: "AKIAQE43JVZPYCT5TQ7O", // Store your access key in environment variables
    secretAccessKey: "YJub9Qlg83kyAqZO8w6rPz1sbUrRFrahjAlf0iEI", // Store your secret key in environment variables
    region: "eu-west-1", // Set your S3 bucket's region
});

const Categories = () => {
    const [categories, setCategories] = useState([]);
    const [newCategory, setNewCategory] = useState({
        title: '',
        image: ''
    });

    const [uploading, setUploading] = useState(false);

    useEffect(() => {
        async function fetchCategories() {
            const response = await getCategories();
            setCategories(response.categories);
        }
        fetchCategories();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewCategory({
            ...newCategory,
            [name]: value
        });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const storageRef = ref(storage, `images/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        setUploading(true);

        uploadTask.on('state_changed',
            (snapshot) => {
                // You can add progress tracking here if needed
            },
            (error) => {
                console.error("Upload failed: ", error);
                setUploading(false);
            },
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                setNewCategory((prevCategory) => ({
                    ...prevCategory,
                    image: downloadURL
                }));
                setUploading(false);
            }
        );
    };

    const handleS3ImageUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;
    
        const params = {
            Bucket: process.env.AWS_S3_BUCKET_NAME, // The name of your S3 bucket
            Key: `images/${file.name}`, // The path inside the bucket
            Body: file,
            ContentType: file.type,
            ACL: 'public-read' // Allows the file to be publicly accessible
        };
    
        setUploading(true);
    
        s3.upload(params, (error, data) => {
            if (error) {
                console.error("Upload failed: ", error);
                setUploading(false);
                return;
            }
    
            setNewCategory((prevCategory) => ({
                ...prevCategory,
                image: data.Location // This is the URL of the uploaded image
            }));
            setUploading(false);
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const addedCategory = await addCategory(newCategory);
        setCategories([...categories, addedCategory]);
        setNewCategory({ title: '', image: '' }); // Reset form after submission
    };

    return (
        <div className="categories-page">
            <h2>Manage Categories</h2>
            
            {/* Add New Category Form */}
            <form onSubmit={handleSubmit} className="category-form">
                <div>
                    <label>Title</label>
                    <input type="text" name="title" value={newCategory.title} onChange={handleChange} required />
                </div>
                <div>
                    <label>Image</label>
                    <input type="file" accept="image/*,.svg" onChange={handleImageUpload} />
                    {uploading && <p>Uploading image...</p>}
                </div>
                {/* <div>
                    <label>Image URL</label>
                    <input type="text" name="image" value={newCategory.image} onChange={handleChange} required />
                </div> */}
                <button type="submit" disabled={uploading}>Add Category</button>
                {/* <button type="submit">Add Category</button> */}
            </form>

            {/* Display Categories */}
            <div className="category-list">
                <h3>Existing Categories</h3>
                <ul>
                    {categories.map(category => (
                        <li key={category._id}>
                            {/* <img src={category.image} alt={category.title} width="50" /> */}
                            <span>{category.title}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default Categories
