import images from "./images"

const data = {
    user: {
        name: 'Rhu',
        img: images.avt
    },
    summary: [
        {
            title: 'Sales',
            subtitle: 'Total sales today',
            value: 'R0.00',
            percent: 0
        },
        {
            title: 'Orders',
            subtitle: 'Total orders today',
            value: '0',
            percent: 0
        },
        {
            title: 'Revenue',
            subtitle: 'Total revenue today',
            value: 'R0.00',
            percent: 0
        },
        {
            title: 'Visits',
            subtitle: 'Total visits today',
            value: '0',
            percent: 0
        }
    ],
    revenueSummary: {
        title: 'Revenue',
        value: 'R0',
        chartData: {
            labels: ['May', 'Jun', 'July', 'Aug', 'May', 'Jun', 'July', 'Aug'],
            data: [0, 0, 0, 0, 0, 0, 0, 0]
        }
    },
    overall: [
        {
            value: '0',
            title: 'Orders'
        },
        {
            value: '0',
            title: 'Customers'
        },
        {
            value: '0',
            title: 'Products'
        },
        {
            value: 'R0',
            title: 'Revenue'
        }
    ],
    revenueByChannel: [
        {
            title: 'Direct',
            value: 0
        },
        {
            title: 'External search',
            value: 0
        },
        {
            title: 'Referal',
            value: 0
        },
        {
            title: 'Social',
            value: 0
        }
    ],
    revenueByMonths: {
        labels: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    }
}

export default data