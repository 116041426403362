import React, { useEffect, useState } from 'react'
import './sidebar.scss'
import { Link, useLocation } from 'react-router-dom'
import { images } from '../../constants'
import sidebarNav from '../../configs/sidebarNav'
import PropTypes from "prop-types";
import {userSignOutRequest} from '../../store/actions/auth'
import { connect } from "react-redux";

const Sidebar = (props) => {

    const { isAuthenticated, user } = props.auth;

    const [activeIndex, setActiveIndex] = useState(0)
    const location = useLocation()

    useEffect(() => {
        const curPath = window.location.pathname.split('/')[1]
        const activeItem = sidebarNav.findIndex(item => item.section === curPath)

        setActiveIndex(curPath.length === 0 ? 0 : activeItem)
    }, [location])

    const closeSidebar = () => {
        document.querySelector('.main__content').style.transform = 'scale(1) translateX(0)'
        setTimeout(() => {
            document.body.classList.remove('sidebar-open')
            document.querySelector('.main__content').style = ''
        }, 500);
    }

    const [logoutModal, setLogoutModal] = React.useState(false);

    const handleLogoutOpen = () => {
        setLogoutModal(true);
      };
    
      const handleLogoutClose = () => {
        setLogoutModal(false);
      };

    const logout = (e) => {
        e.preventDefault();
        // handleLogoutClose();
        props.userSignOutRequest();
        console.log("hello")
      };

    return (
        <div className='sidebar'>
            <div className="sidebar__logo">
                <img src={images.logo} alt="" />
                <div className="sidebar-close" onClick={closeSidebar}>
                    <i className='bx bx-x'></i>
                </div>
            </div>
            <div className="sidebar__menu">
                {
                    sidebarNav.map((nav, index) => (
                        <Link to={nav.link} key={`nav-${index}`} className={`sidebar__menu__item ${activeIndex === index && 'active'}`} onClick={closeSidebar}>
                            <div className="sidebar__menu__item__icon">
                                {nav.icon}
                            </div>
                            <div className="sidebar__menu__item__txt">
                                {nav.text}
                            </div>
                        </Link>
                    ))
                }
                <div className="sidebar__menu__item" onClick={logout}>
                    <div className="sidebar__menu__item__icon">
                        <i className='bx bx-log-out'></i>
                    </div>
                    <div className="sidebar__menu__item__txt">
                        Logout
                    </div>
                </div>
            </div>
        </div>
    )
}

//export default Sidebar
Sidebar.propTypes = {
    auth: PropTypes.object.isRequired,
    userSignOutRequest: PropTypes.func.isRequired,
  };
  
  function mapStateToProps(state) {
    return {
      auth: state.auth,
    };
  }
  
  export default connect(mapStateToProps, { userSignOutRequest })(Sidebar);