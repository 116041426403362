// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topnav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 48px;
}
@media only screen and (max-width: 1366px) {
  .topnav {
    margin-bottom: 24px;
  }
}

.sidebar-toggle {
  --size: 40px;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: none;
}
@media only screen and (max-width: 1366px) {
  .sidebar-toggle {
    display: flex;
  }
}
.sidebar-toggle i {
  font-size: 3rem;
  color: #8624DB;
}`, "",{"version":3,"sources":["webpack://./src/components/topnav/topnav.scss","webpack://./src/scss/abstracts/_mixins.scss","webpack://./src/scss/abstracts/_variables.scss"],"names":[],"mappings":"AAEA;ECCI,aAAA;EACA,mBDDc;ECEd,8BDFsB;EACtB,mBEeM;AFdV;ACUI;EDbJ;IAKQ,mBEaQ;EFXd;AACF;;AACA;EACI,YAAA;EACA,kBAAA;EACA,mBAAA;ECXA,aAAA;EACA,mBDWc;ECVd,uBDUsB;EACtB,eAAA;EACA,aAAA;AAIJ;ACNI;EDJJ;IASQ,aAAA;EAKN;AACF;AAHI;EACI,eAAA;EACA,cEvBI;AF4BZ","sourcesContent":["@use '../../scss/abstracts/' as *;\n\n.topnav {\n    @include flex(center, space-between);\n    margin-bottom: $spacing;\n\n    @include tablet {\n        margin-bottom: $mobileSpacing;\n    }\n}\n\n.sidebar-toggle {\n    --size: 40px;\n    width: var(--size);\n    height: var(--size);\n    @include flex(center, center);\n    cursor: pointer;\n    display: none;\n\n    @include tablet {\n        display: flex;\n    }\n    \n    i {\n        font-size: 3rem;\n        color: $mainColor;\n    }\n}","@use './variables' as *;\n\n@mixin flex($alignItem: center, $justifyContent: center) {\n    display: flex;\n    align-items: $alignItem;\n    justify-content: $justifyContent;\n}\n\n@mixin mobile {\n    @media only screen and (max-width: $mobile-width) {\n        @content;\n    }\n}\n\n@mixin tablet {\n    @media only screen and (max-width: $tablet-width) {\n        @content;\n    }\n}","$bodyBg: #F3F4FA;\n$mainBg: #fff;\n$mainColor: #8624DB;\n$txtColor: #4E4D4F;\n$purple: #8624DB;\n$orange: #FF9066;\n$white: #fff;\n$black: #000;\n$green: #4CAF50;\n$red: #DB190C;\n\n$fontFamily: 'Roboto', sans-serif;\n\n$fontSizeNormal: 1.125rem;\n$fontSizeMedium: 1.25rem;\n$fontSizeLarge: 1.5625rem;\n\n$sideBarWidth: 384px;\n$sideBarLogoHeight: 171px;\n$spacing: 48px;\n$mobileSpacing: 24px;\n$borderRadius: 30px;\n\n$mobile-width: 600px;\n$tablet-width: 1366px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
