// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  border-radius: 30px;
  height: 50px;
  width: max-content;
}
.user-info__img {
  --size: 50px;
  width: var(--size);
  height: var(--size);
  border-radius: 30px;
}
.user-info__img img {
  border-radius: 30px;
}
.user-info__name {
  padding: 0 48px;
  font-size: 1.25rem;
  font-weight: 700;
}
@media only screen and (max-width: 1366px) {
  .user-info__name {
    padding: 0 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/user-info/user-info.scss","webpack://./src/scss/abstracts/_mixins.scss","webpack://./src/scss/abstracts/_variables.scss"],"names":[],"mappings":"AAEA;ECCI,aAAA;EACA,mBDDc;ECEd,2BDFsB;EACtB,sBEHK;EFIL,mBEgBW;EFfX,YAAA;EACA,kBAAA;AACJ;AACI;EACI,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBEQO;AFPf;AACQ;EACI,mBEKG;AFJf;AAGI;EACI,eAAA;EACA,kBERS;EFST,gBAAA;AADR;ACPI;EDKA;IAMQ,eAAA;EAAV;AACF","sourcesContent":["@use '../../scss/abstracts/' as *;\n\n.user-info {\n    @include flex(center, flex-start);\n    background-color: $mainBg;\n    border-radius: $borderRadius;\n    height: 50px;\n    width: max-content;\n\n    &__img {\n        --size: 50px;\n        width: var(--size);\n        height: var(--size);\n        border-radius: $borderRadius;\n\n        img {\n            border-radius: $borderRadius;\n        }\n    }\n\n    &__name {\n        padding: 0 $spacing;\n        font-size: $fontSizeMedium;\n        font-weight: 700;\n\n        @include tablet {\n            padding: 0 $mobileSpacing;\n        }\n    }\n}","@use './variables' as *;\n\n@mixin flex($alignItem: center, $justifyContent: center) {\n    display: flex;\n    align-items: $alignItem;\n    justify-content: $justifyContent;\n}\n\n@mixin mobile {\n    @media only screen and (max-width: $mobile-width) {\n        @content;\n    }\n}\n\n@mixin tablet {\n    @media only screen and (max-width: $tablet-width) {\n        @content;\n    }\n}","$bodyBg: #F3F4FA;\n$mainBg: #fff;\n$mainColor: #8624DB;\n$txtColor: #4E4D4F;\n$purple: #8624DB;\n$orange: #FF9066;\n$white: #fff;\n$black: #000;\n$green: #4CAF50;\n$red: #DB190C;\n\n$fontFamily: 'Roboto', sans-serif;\n\n$fontSizeNormal: 1.125rem;\n$fontSizeMedium: 1.25rem;\n$fontSizeLarge: 1.5625rem;\n\n$sideBarWidth: 384px;\n$sideBarLogoHeight: 171px;\n$spacing: 48px;\n$mobileSpacing: 24px;\n$borderRadius: 30px;\n\n$mobile-width: 600px;\n$tablet-width: 1366px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
