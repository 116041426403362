// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress-bar {
  height: 10px;
  background-color: #F3F4FA;
  border-radius: 2.5px;
}
.progress-bar__inner {
  border-radius: 2.5px;
  height: 100%;
  background-color: #FF9066;
}`, "",{"version":3,"sources":["webpack://./src/components/progressbar/progress-bar.scss","webpack://./src/scss/abstracts/_variables.scss"],"names":[],"mappings":"AAEA;EACI,YAAA;EACA,yBCJK;EDKL,oBAAA;AADJ;AAGI;EACI,oBAAA;EACA,YAAA;EACA,yBCLC;ADIT","sourcesContent":["@use '../../scss/abstracts/' as *;\n\n.progress-bar {\n    height: 10px;\n    background-color: $bodyBg;\n    border-radius: 2.5px;\n\n    &__inner {\n        border-radius: 2.5px;\n        height: 100%;\n        background-color: $orange;\n    }\n}","$bodyBg: #F3F4FA;\n$mainBg: #fff;\n$mainColor: #8624DB;\n$txtColor: #4E4D4F;\n$purple: #8624DB;\n$orange: #FF9066;\n$white: #fff;\n$black: #000;\n$green: #4CAF50;\n$red: #DB190C;\n\n$fontFamily: 'Roboto', sans-serif;\n\n$fontSizeNormal: 1.125rem;\n$fontSizeMedium: 1.25rem;\n$fontSizeLarge: 1.5625rem;\n\n$sideBarWidth: 384px;\n$sideBarLogoHeight: 171px;\n$spacing: 48px;\n$mobileSpacing: 24px;\n$borderRadius: 30px;\n\n$mobile-width: 600px;\n$tablet-width: 1366px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
