// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.revenue-list > * ~ * {
  margin-top: 24px;
}
.revenue-list__item__title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.25rem;
  margin-bottom: 8px;
}
.revenue-list__item__title span {
  font-weight: 700;
}`, "",{"version":3,"sources":["webpack://./src/components/revenue-list/revenue-list.scss","webpack://./src/scss/abstracts/_mixins.scss","webpack://./src/scss/abstracts/_variables.scss"],"names":[],"mappings":"AAII;EACI,gBAAA;AAHR;AAOQ;ECNJ,aAAA;EACA,mBDMsB;ECLtB,8BDK8B;EACtB,kBEGK;EFFL,kBAAA;AAHZ;AAKY;EACI,gBAAA;AAHhB","sourcesContent":["@use '../../scss/abstracts/' as *;\n@use 'sass:math';\n\n.revenue-list {\n    & > * ~ * {\n        margin-top: math.div($spacing, 2);\n    }\n\n    &__item {\n        &__title {\n            @include flex(center, space-between);\n            font-size: $fontSizeMedium;\n            margin-bottom: math.div($spacing, 6);\n\n            span {\n                font-weight: 700;\n            }\n        }\n    }\n}","@use './variables' as *;\n\n@mixin flex($alignItem: center, $justifyContent: center) {\n    display: flex;\n    align-items: $alignItem;\n    justify-content: $justifyContent;\n}\n\n@mixin mobile {\n    @media only screen and (max-width: $mobile-width) {\n        @content;\n    }\n}\n\n@mixin tablet {\n    @media only screen and (max-width: $tablet-width) {\n        @content;\n    }\n}","$bodyBg: #F3F4FA;\n$mainBg: #fff;\n$mainColor: #8624DB;\n$txtColor: #4E4D4F;\n$purple: #8624DB;\n$orange: #FF9066;\n$white: #fff;\n$black: #000;\n$green: #4CAF50;\n$red: #DB190C;\n\n$fontFamily: 'Roboto', sans-serif;\n\n$fontSizeNormal: 1.125rem;\n$fontSizeMedium: 1.25rem;\n$fontSizeLarge: 1.5625rem;\n\n$sideBarWidth: 384px;\n$sideBarLogoHeight: 171px;\n$spacing: 48px;\n$mobileSpacing: 24px;\n$borderRadius: 30px;\n\n$mobile-width: 600px;\n$tablet-width: 1366px;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
