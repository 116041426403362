import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import { getProducts, deleteService } from '../../services/productService';
import {
    Paper,
    withStyles,
    Grid,
    TextField,
    Button,
    FormControlLabel,
    Checkbox,
    Typography,
    InputAdornment,
    CircularProgress,
    Box,
  } from "@material-ui/core";

import { useNavigate } from 'react-router-dom';


const Products = () => {
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [productId, setProduct] = useState('');

    useEffect(() => {
        async function fetchData() {
            const response = await getProducts();
            if (!response.error) {
                setProducts(response.products);
                setCategories(response.categories);
            } else {
                console.error('Error fetching products:', response);
            }
        }
        fetchData();
    }, []);

    // Helper function to get category names
    const getCategoryNames = (productCategories) => {
        return productCategories.map(categoryId => {
            const category = categories.find(cat => cat._id === categoryId);
            return category ? category.title : 'Unknown';
        }).join(', ');
    };

    const navigate = useNavigate(); // Initialize the navigate hook

    const handleSubmit = async (e) => {
        e.preventDefault();

        console.log(e)
        //await deleteService(product);
        //navigate('/products'); // Redirect to products page after submission
    };

    const handleDelete = async (productId) => {
        try {
          // Log the product ID
          console.log("Deleting product with ID:", productId);
      
          // Here you would call your delete service/API
          // await deleteProductService(productId);
      
          // Optionally update the UI, like removing the deleted product from the list
          // setProducts(products.filter(product => product._id !== productId));

            await deleteService({"id":productId});
            window.location.reload();
            //navigate('/products'); // Redirect to products page after submission
        } catch (error) {
          console.error("Error deleting product:", error);
        }
      };

    return (
        <div className="products">
            <h2>Products</h2>
            <div
            style={{
                flexDirection: "row",
                justifyContent: "flex-end",
            }}>
                <Link to={"/products/new"}>
                    <Button
                    variant="contained"
                    color="primary"
                    style={{
                        boxShadow: "none",
                        width: 100,
                        marginBottom: "1rem",
                        textTransform: "none",
                    }}
                    //   onClick={onSubmit}
                    //   disabled={submit}
                    >
                        <Typography>Add Product</Typography>
                    </Button>
                </Link>
            </div>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Price</th>
                        <th>Stock</th>
                        <th>Size</th>
                        <th>Unit</th>
                        <th>Categories</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map(product => (
                        <tr key={product._id}>
                            <td>{product.name}</td>
                            <td>{product.price}</td>
                            <td>{product.stock}</td>
                            <td>{product.size}</td>
                            <td>{product.unit}</td>
                            <td>{getCategoryNames(product.categories)}</td>
                            <td>
                                <button>Edit</button>
                                <button onClick={() => handleDelete(product._id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Products
