import Constants from '../utils/Constants'
export const getProducts = async () => {

    try {
    const response = await fetch(Constants.GET_PRODUCTS_URL, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json'
    }});

    // console.log("consoling")
    // console.log(response.json())
    return response.json();
    } catch (error) {
        console.log(error)
    }
};

export const addProduct = async (product) => {
    console.log(JSON.stringify(product))
    const response = await fetch(Constants.CREATE_PRODUCT, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(product)
    });

    
    return response.json();
};

export const getAddProductData = async () => {

    try {
    const response = await fetch(Constants.GET_ADD_PRODUCTS_DATA_URL, {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json'
    }});
    
    const data = await response.json();
    return {
        categories: data.categories,
        sizeUnits: data.sizeUnits,
        sizes: data.sizes
    };
    } catch (error) {
        console.log(error)
    }
};

export const deleteService = async (id) => {
    const response = await fetch(Constants.DELETE_SERVICE_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(id)
    });
    return response.json();
};