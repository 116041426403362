import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDG6qyxp5Q7sQeFb3U4Nu6jSb7yfoB6q8E",
    authDomain: "kushdrop-6148e.firebaseapp.com",
    projectId: "kushdrop-6148e",
    storageBucket: "kushdrop-6148e.appspot.com",
    messagingSenderId: "476539976483",
    appId: "1:476539976483:web:8c973c5a1eb543cf6faa96",
    measurementId: "G-18W92NTP4Y"
  };

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);

export { storage };
