import React from 'react';

const Header = () => {
    return (
        <header className="header">
            <h1>Admin Panel</h1>
            <div className="user-menu">
                <span>Admin</span>
                <button>Logout</button>
            </div>
        </header>
    );
};

export default Header;