// import logo from './logo.svg';
//import './App.css';
import './assets/libs/boxicons-2.1.1/css/boxicons.min.css'
import './scss/App.scss'
import { BrowserRouter, Routes, Route, Switch, Link, Redirect, Navigate} from 'react-router-dom';
// import Dashboard from './pages/Dashboard';
// import Products from './pages/Products';
import Orders from './pages/Orders';
import Customers from './pages/Customers';
// import Login from './pages/Login';
import Settings from './pages/Settings';
import Header from './components/Header';
import Sidebar from './components/Sidebar';
import Footer from './components/Footer';
import ProductForm from './pages/ProductForm'
import Categories from './pages/Categories/Categories';
import Sizes from './pages/Sizes/Sizes'

//
import React, { Fragment } from "react";
// import { BrowserRouter, Routes, Route, Switch, Link, Redirect, Navigate} from 'react-router-dom'
import Blank from './pages/Blank'
import Dashboard from './pages/Dashboard/Dashboard'
import MainLayout from './layout/MainLayout'

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userSignOutRequest } from "./store/actions/auth";
import Login from './pages/Login/Login';
import Products from './pages/Products/Products';

function App(props) {
  const { isAuthenticated, user } = props.auth;

    const [logoutModal, setLogoutModal] = React.useState(false);

    const RedirectToDashboard = () => (
        <Fragment>
          {isAuthenticated ? <Navigate to="/" /> : null}
        </Fragment>
      );
    
      const handleLogoutOpen = () => {
        setLogoutModal(true);
      };
    
      const handleLogoutClose = () => {
        setLogoutModal(false);
      };
    
      const logout = (e) => {
        e.preventDefault();
        handleLogoutClose();
        props.userSignOutRequest();
      };

      if (isAuthenticated) {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<MainLayout />}>
                        <Route index element={<Dashboard />} />
                        <Route path="orders" element={<Blank />} />
                        <Route path="products" element={<Products />} />
                        <Route path='/categories' element={<Categories />} />
                        <Route path='/sizes' element={<Sizes />} />
                        <Route path="customers" element={<Blank />} />
                        <Route path="settings" element={<Blank />} />
                        <Route path="stats" element={<Blank />} />
                        <Route path="/products/new" element={<ProductForm />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        )
      } else {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login />}>
                        <Route index element={<Dashboard />} />
                        <Route path="orders" element={<Blank />} />
                        <Route path="products" element={<Blank />} />
                        <Route path="customers" element={<Blank />} />
                        <Route path="settings" element={<Blank />} />
                        <Route path="stats" element={<Blank />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        )
      }
  // return (
  //     <Router>
  //         <div className="app-container">
  //             <Header />
  //             <div className="main-content">
  //                 <Sidebar />
  //                 <div className="content-area">
  //                     <Routes>
  //                         <Route path="/" element={<Dashboard />} />
  //                         <Route path="/products" element={<Products />} />
  //                         <Route path="/products/new" element={<ProductForm />} />
  //                         <Route path='/categories' element={<Categories />} />
  //                         <Route path="/orders" element={<Orders />} />
  //                         <Route path="/customers" element={<Customers />} />
  //                         <Route path="/login" element={<Login />} />
  //                         <Route path="/settings" element={<Settings />} />
  //                     </Routes>
  //                 </div>
  //             </div>
  //             <Footer />
  //         </div>
  //     </Router>
  // );
}

App.propTypes = {
  auth: PropTypes.object.isRequired,
  userSignOutRequest: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps, { userSignOutRequest })(App);
