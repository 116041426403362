// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.box {
  padding: 20px 30px;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.box-purple {
  background-color: #8624DB;
  color: #fff;
}
.box-fullheight {
  height: calc(100% - 48px);
}
@media only screen and (max-width: 1366px) {
  .box-fullheight {
    height: calc(100% - 24px);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/box/box.scss","webpack://./src/scss/abstracts/_variables.scss","webpack://./src/scss/abstracts/_mixins.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,mBCiBW;EDhBX,sBCJK;EDKL,iDAAA;AADJ;AAGI;EACI,yBCLC;EDMD,WCJA;ADGR;AAII;EACI,yBAAA;AAFR;AEGI;EFFA;IAIQ,yBAAA;EADV;AACF","sourcesContent":["@use '../../scss/abstracts/' as *;\n\n.box {\n    padding: 20px 30px;\n    border-radius: $borderRadius;\n    background-color: $mainBg;\n    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;\n\n    &-purple {\n        background-color: $purple;\n        color: $white;\n    }\n\n    &-fullheight {\n        height: calc(100% - $spacing);\n\n        @include tablet {\n            height: calc(100% - $mobileSpacing);\n        }\n    }\n}","$bodyBg: #F3F4FA;\n$mainBg: #fff;\n$mainColor: #8624DB;\n$txtColor: #4E4D4F;\n$purple: #8624DB;\n$orange: #FF9066;\n$white: #fff;\n$black: #000;\n$green: #4CAF50;\n$red: #DB190C;\n\n$fontFamily: 'Roboto', sans-serif;\n\n$fontSizeNormal: 1.125rem;\n$fontSizeMedium: 1.25rem;\n$fontSizeLarge: 1.5625rem;\n\n$sideBarWidth: 384px;\n$sideBarLogoHeight: 171px;\n$spacing: 48px;\n$mobileSpacing: 24px;\n$borderRadius: 30px;\n\n$mobile-width: 600px;\n$tablet-width: 1366px;\n","@use './variables' as *;\n\n@mixin flex($alignItem: center, $justifyContent: center) {\n    display: flex;\n    align-items: $alignItem;\n    justify-content: $justifyContent;\n}\n\n@mixin mobile {\n    @media only screen and (max-width: $mobile-width) {\n        @content;\n    }\n}\n\n@mixin tablet {\n    @media only screen and (max-width: $tablet-width) {\n        @content;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
