import React from 'react';

const Customers = () => {
    return (
        <div className="customers">
            <h2>Customers</h2>
            <p>Manage customer information here.</p>
        </div>
    );
};

export default Customers;
