import Constants from "../utils/Constants";


// Fetch all categories
export const getSizes = async () => {
    const response = await fetch(Constants.GET_SIZES_URL);
    const data = await response.json();
    console.log("data "+ data)
    return data;
};

// Add a new category
export const addSizes = async (size) => {
    const response = await fetch(Constants.CREATE_SIZES_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(size)
    });
    return response.json();
};
