import React, { useState, useEffect } from 'react';
import { addProduct } from '../services/productService';
import { getAddProductData } from '../services/productService';
import { useNavigate } from 'react-router-dom';
import { storage } from '../configs/firebaseConfig'
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';


import AWS from 'aws-sdk';

const s3 = new AWS.S3({
    accessKeyId: "AKIAQE43JVZPYCT5TQ7O", // Store your access key in environment variables
    secretAccessKey: "YJub9Qlg83kyAqZO8w6rPz1sbUrRFrahjAlf0iEI", // Store your secret key in environment variables
    region: "eu-west-1", // Set your S3 bucket's region
});

const corsRules = [
    {
      AllowedOrigins: ['*'],  // Replace '*' with your specific origin if needed
      AllowedMethods: ['GET', 'POST', 'PUT', 'DELETE'],  // HTTP methods you allow
      AllowedHeaders: ['*'],  // Allow any headers (can restrict if needed)
      ExposeHeaders: ['ETag'],  // Optional, expose additional headers
      MaxAgeSeconds: 3000  // How long the results of a preflight request can be cached
    }
  ];
  
  const params = {
    Bucket: 'kushdrop',
    CORSConfiguration: {
      CORSRules: corsRules
    }
  };

const ProductForm = () => {
    const [product, setProduct] = useState({
        image: '',
        name: '',
        quantity: 0,
        price: 0.00,
        description: '',
        stock: 0,
        size: 0,
        unit: 'grams', // Default unit
        categories: [],
        sizes: []
    });

    const navigate = useNavigate(); // Initialize the navigate hook

    const [categories, setCategories] = useState([]);
    const [sizeUnits, setSizeUnits] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [sizes, setSizes] = useState([]);

    useEffect(() => {
        async function fetchCategories() {
            const { categories, sizeUnits, sizes } = await getAddProductData();
            setCategories(categories);
            setSizeUnits(sizeUnits);
            setSizes(sizes);
        }
        fetchCategories();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProduct({
            ...product,
            [name]: value
        });
    };

    const handleCategoryChange = (e) => {
        const selectedCategories = Array.from(e.target.selectedOptions, option => option.value);
        setProduct({
            ...product,
            categories: selectedCategories
        });
    };

    const handleSizeChange = (e) => {
        const selectedSizes = Array.from(e.target.selectedOptions, option => option.value);
        setProduct({
            ...product,
            sizes: selectedSizes
        });
    };

    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        const storageRef = ref(storage, `images/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);

        setUploading(true);

        uploadTask.on('state_changed', 
            (snapshot) => {
                // You can add progress tracking here if needed
            }, 
            (error) => {
                console.error("Upload failed: ", error);
                setUploading(false);
            }, 
            async () => {
                const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                setProduct((prevProduct) => ({
                    ...prevProduct,
                    image: downloadURL
                }));
                setUploading(false);
            }
        );
    };

    const handleS3ImageUpload = (e) => {
        const file = e.target.files[0];
        if (!file) return;
    
        const params = {
            Bucket: "kushdrop", // Your S3 bucket name
            Key: `images/${file.name}`, // File path in the bucket
            Body: file, // The file itself
            ContentType: file.type, // File type (MIME)
        };
    
        setUploading(true);
    
        s3.upload(params, (error, data) => {
            if (error) {
                console.error("Upload failed: ", error);
                setUploading(false);
                return;
            }
    
            // On successful upload, use the file URL (data.Location) to update your product state
            setProduct((prevProduct) => ({
                ...prevProduct,
                image: data.Location // S3 URL of the uploaded file
            }));
            setUploading(false);
        });
    };

    

    const handleSubmit = async (e) => {
        e.preventDefault();
        await addProduct(product);
        navigate('/products'); // Redirect to products page after submission
    };


    return (
        <div className="product-form">
            <h2>Add New Product</h2>
            <form onSubmit={handleSubmit}>
                {/* <div>
                    <label>Image URL</label>
                    <input type="text" name="image" value={product.image} onChange={handleChange} />
                </div> */}
                <div>
                    <label>Image</label>
                    <input type="file" accept="image/*,.svg" onChange={handleS3ImageUpload} />
                    {uploading && <p>Uploading image...</p>}
                </div>
                <div>
                    <label>Product Name</label>
                    <input type="text" name="name" value={product.name} onChange={handleChange} />
                </div>
                <div>
                    <label>Quantity</label>
                    <input type="number" name="quantity" value={product.quantity} onChange={handleChange} />
                </div>
                <div>
                    <label>Price</label>
                    <input type="number" step="0.01" name="price" value={product.price} onChange={handleChange} />
                </div>
                <div>
                    <label>Description</label>
                    <textarea name="description" value={product.description} onChange={handleChange}></textarea>
                </div>
                <div>
                    <label>Stock</label>
                    <input type="number" name="stock" value={product.stock} onChange={handleChange} />
                </div>
                <div>
                    <label>Size</label>
                    <input type="number" name="size" value={product.size} onChange={handleChange} />
                </div>
                <div>
                    <label>Unit</label>
                    <select name="unit" value={product.unit} onChange={handleChange}>
                        {sizeUnits.map(unit => (
                            <option key={unit} value={unit}>{unit}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Categories</label>
                    <select multiple={true} value={product.categories} onChange={handleCategoryChange}>
                        {categories.map(category => (
                            <option key={category._id} value={category._id}>{category.title}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <label>Sizes</label>
                    <select multiple={true} value={product.sizes} onChange={handleSizeChange}>
                        {sizes.map(size => (
                            <option key={size._id} value={size._id}>{size.title}</option>
                        ))}
                    </select>
                </div>
                <button type="submit" disabled={uploading}>Add Product</button>
                {/* <button type="submit">Add Product</button> */}
            </form>
        </div>
    );
};

export default ProductForm;
