import React, { useState, useEffect } from 'react';
import { addSizes, getSizes } from '../../services/sizesService';
const Sizess = () => {
    const [sizes, setSizes] = useState([]);
    const [newSize, setNewsize] = useState({
        title: ''
    });

    useEffect(() => {
        async function fetchCategories() {
            const response = await getSizes();
            setSizes(response.sizes);
        }
        fetchCategories();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewsize({
            ...newSize,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const addedSize = await addSizes(newSize);
        setSizes([...sizes, addedSize]);
        setNewsize({ title: ''}); // Reset form after submission
    };

    return (
        <div className="categories-page">
            <h2>Manage Sizes</h2>
            
            {/* Add New Category Form */}
            <form onSubmit={handleSubmit} className="sizes-form">
                <div>
                    <label>Title</label>
                    <input type="text" name="title" value={newSize.title} onChange={handleChange} required />
                </div>
                {/* <div>
                    <label>Image URL</label>
                    <input type="text" name="image" value={newCategory.image} onChange={handleChange} required />
                </div> */}
                <button type="submit" >Add Size</button>
                {/* <button type="submit">Add Category</button> */}
            </form>

            {/* Display Categories */}
            <div className="category-list">
                <h3>Existing Sizes</h3>
                <ul>
                    
                    {sizes.map(size => (
                        <li key={size._id}>
                            {/* <img src={category.image} alt={category.title} width="50" /> */}
                            <span>{size.title}</span>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default Sizess